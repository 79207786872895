.txt-justify {
    text-align: justify !important;
}

.text-headings {
    font-size: 24px !important;
    text-align: start !important;
}

@media (min-width: 40em) {
    .features-heading {
        font-size: 50px;
    }
}

@media (min-width: 62em) {
    .features-heading {
        margin-top: 0 !important;
    }
}