.privacy-policy-wrapper {
    height: 95vh;
    background-color: transparent;
}

.p-policy-image-one {
    /* width: 475px; */
    width: -webkit-fill-available;
}

.privacy-policy-image-container {
    position: relative;
    display: block;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.flex-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 768px) {
    .privacy-policy-wrapper {
        height: 100vh;
    }

    .privacy-policy-image-container{
        display: block;
    }

    .p-policy-image-one {
        width: -webkit-fill-available;
    }

    .flex-wrapper{
        display: block;
    }
}