#contact-us .section-heading {
  margin-bottom: 50px;
}

#contact-us .section-heading h6 {
  color: var(--secondary-color);
}

#contact-us .left-text-content ul li {
  margin-bottom: 30px;
}

#contact-us .left-text-content ul li {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

#contact-us .left-text-content ul li img {
  margin-right: 15px;
}

#contact-us {
  padding: 60px 0px;
  background-color: var(--app-primary-bg-color);
  background-image: url(../../assets/backgrounds/dotted-map-bg.png);
  background-position: center center;
  background-repeat: no-repeat;
}

#contact {
  margin-top: 40px;
}

.contact-info {
  list-style: none !important;
}

.mail-link,
.company-link {
  color: var(--primary-font-color);
  text-decoration: none !important;
}

.mail-link:hover,
.mail-link:active,
.company-link:hover,
.company-link:active {
  color: var(--secondary-color);
}

.contact-form input,
.contact-form textarea {
  color: #7a7a7a;
  font-size: 15px;
  border: 1px solid #ddd;
  background-color: #fff;
  width: 100%;
  height: 46px;
  outline: none;
  padding-top: 3px;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-bottom: 30px;
}

.contact-form textarea {
  height: 150px;
  resize: none;
  padding: 20px;
}

.contact-form ::-webkit-input-placeholder {
  /* Edge */
  color: #7a7a7a;
}

.contact-form :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #7a7a7a;
}

.contact-form ::placeholder {
  color: #7a7a7a;
}

.phone-number {
  color: var(--primary-font-color);
  ;
}

/*
--------------------------------------------------  
SUMIT BUTTON
-------------------------------------------------- 
*/

button.main-button {
  outline: none;
  border: none;
  cursor: pointer;
  font-size: 13px;
  border-radius: 25px;
  padding: 10px 25px;
  background-color: #fff;
  text-transform: uppercase;
  color: var(--secondary-color);
  font-weight: 600;
  letter-spacing: 1px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

button.main-button-icon:hover {
  color: #fff;
  background-color: rgb(255, 194, 48, 1) !important;
  background-color: linear-gradient(145deg, rgb(255, 194, 48, 1) 0%, rgb(255, 124, 0) 100%) !important;
}

button.main-button-icon {
  border: none;
  outline: none;
  font-size: 13px;
  border-radius: 25px;
  padding: 13px 25px;
  background-color: var(--secondary-color);
  text-transform: uppercase;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

button.main-button-icon i {
  margin-left: 5px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

button.main-button-icon:hover i {
  padding-left: 5px;
}

/*
--------------------------------------------------  
FORM ALERT
-------------------------------------------------- 
*/

.alert {
  padding: 15px;
  margin-top: 20px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.alert-success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.alert-danger {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.alert button {
  background: none;
  border: none;
  font-size: 20px;
  line-height: 20px;
  cursor: pointer;
  color: inherit;
}

.alert button:hover {
  opacity: 0.7;
}

/*
--------------------------------------------------  
FORM LOADER
-------------------------------------------------- 
*/

.loader {
  border: 4px solid #f3f3f3; 
  border-top: 4px solid var(--secondary-color);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  display: inline-block;
  vertical-align: middle;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@media (max-width: 768px) {
  #contact-us {
    margin-top: 10rem;
  }
}