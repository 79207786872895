.fab-container {
    position: fixed;
    bottom: 20px; /*120px*/
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000;
}

.fab-main-button {
    background-color: var(--primary-color);
    border: none;
    border-radius: 15px; /* Rounded corners for chat-bubble shape */
    width: 150px;
    height: 53px;
    color: white;
    font-size: 24px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 10px 15px;
}

.fab-main-button::after {
    content: "";
    position: absolute;
    bottom: -10px; /* Positioning the tail */
    left: 20px; /* Adjust to align the tail */
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: var(--primary-color); /* Same color as bubble */
    border-bottom: 0;
    border-left: 0;
    margin-left: -5px;
}

.helpOutline-icon-wrapper {
    font-weight: 500;
    padding: 16px 0;
}

.helpOutline-icon {
    font-size: 35px !important;
}

.fab-hidden {
    display: none;
}

.fab-main-button.rotate {
    transform: rotate(90deg);
}

.fab-menu {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: end;
    /*center*/
    justify-content: center;
    /* Ensures menu buttons align centrally */
}

.close-icon,
.add-icon {
    font-size: 30px;
}

.fab-item:hover {
    background-color: #f0f0f0;
}