.slick-arrow{
    display: none !important;
}

.testimonial {
    position: relative;
    /* margin: 45px 0; */
    padding: 120px 0;
    background: linear-gradient(rgba(0, 0, 0, 0.8), rgb(26, 26, 26), rgba(0, 0, 0, 0.8)), url(../../assets/backgrounds/subscribe-bg.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.testimonial .container {
    max-width: 700px;
}

.testimonial .testimonials-carousel {
    position: relative;
    width: 100%;
}

.testimonial .testimonial-item {
    position: relative;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
}

.testimonial .testimonial-item img {
    width: 60px;
    border-radius: 60px;
    margin-bottom: 25px;
}

.testimonial .testimonial-item p {
    color: #ffffff;
    font-size: 16px;
    margin-bottom: 20px;
}

.testimonial .testimonial-item h2 {
    color: var(--secondary-color) !important;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
}

.testimonial .testimonial-item h3 {
    color: #999999;
    font-size: 16px;
    font-weight: 300;
    margin: 0;
}

/* Outline button styles */
.button-wrapper {
    text-align: start;
    margin-top: 20px;
}

.outline-button {
    background-color: transparent;
    border: 2px solid var(--primary-color);
    color: var(--primary-color);
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 24px;
    transition: all 0.3s ease;
}

.outline-button:hover {
    background-color: var(--primary-color);
    color: white;
}
