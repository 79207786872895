.landingPage-wrapper {
    min-height: 100vh;
    /* Ensures the section covers the full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    /* Prevents horizontal overflow */
    box-sizing: border-box;
    /* Ensures padding is included within the width/height */

    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: darken;
    background-image: url(../../../assets/svgs/register-02.svg);
}

.contactus-wrapper {
    min-height: 100vh;
    /* Ensures the section covers the full viewport height */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    /* Prevents horizontal overflow */
    box-sizing: border-box;
    /* Ensures padding is included within the width/height */
}

/* Optional: If the row flexbox isn't working correctly for responsive design, tweak the column layout */
.row {
    margin-right: 0;
    margin-left: 0;
}

img {
    max-width: 100%;
    height: auto;
    /* Ensure the image scales down for responsiveness */
}

.zonezapp-text {
    font-size: 32px;
    font-weight: bold;
    margin-left: 0.8rem;
    color: var(--primary-font-color);
}

.form-bg{
    background-color: #f2f3f8;
}

/* Additional styling for smaller screens */
@media (max-width: 768px) {
    .landingPage-wrapper {
        padding: 20px;
        /* Add padding for smaller screens to prevent content overflow */
    }

    .model-cover img {
        width: 100%;
        /* Ensure image resizes for mobile view */
        margin-top: 4rem;
    }
}