.back-to-top {
    /* position: fixed;
    bottom: 20px;
    right: 20px; */
    margin: 0.25rem 0 ;
    width: 44px;
    height: 44px;
    border: none;
    border-radius: 50%;
    background-color: #ffffff;
    /* Adjust color as needed */
    color: var(--secondary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, box-shadow 0.3s, opacity 0.3s;
    opacity: 0;
    /* Start with the button hidden */
}

.back-to-top.visible {
    opacity: 1;
    /* Show the button when scrolled down */
}

.expandless-icon{
    font-size: 30px !important;
}

.back-to-top:hover {
    background: rgb(255, 194, 48, 1);
    background: linear-gradient(145deg, rgb(255, 194, 48, 1) 0%, rgb(255, 124, 0) 100%);
    /* Adjust hover color as needed */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}