/* index.css */

@mixin webkit-scrollbar() {
  width: .50rem;
  border-radius: .5rem;
  background-color: var(--app-acent-bg-color);
}

@mixin webkit-scrollbar-thumb() {
  border-radius: .5rem;
  background-color: var(--secondary-color);
}

:root {

  --primary-color: #ff9900;
  --secondary-color: #ff6f00;
  --accent-color: #fe8b48;

  --app-secondary-bg-color: #ffffff;
  --app-accent-bg-color-one: #fff4f4;
  --app-accent-bg-color-two: #f2f3f8;
  --app-primary-bg-color-three: #ffead9;
  --app-primary-bg-color: var(--app-accent-bg-color-two);

  --primary-font-color: #171717;
  --secondary-font-color: #cccccc;
  --accent-font-color: #707070;

  --primary-border-lining-color: #e5e5e5;

  --primary-box-shadow: none;

  --dm-primary-color: #0c0e14;
  --dm-accent-color: #10121A;
}

body.dark {
  color: var(--secondary-font-color);
  background-color: var(--dm-primary-color);

  &::-webkit-scrollbar {
    @include webkit-scrollbar();
  }

  &::-webkit-scrollbar-thumb {
    @include webkit-scrollbar-thumb();
  }

  .navbar {
    border: none;
    background-color: var(--dm-accent-color);
  }

  .brand,
  .nav-link,
  .typewriter-text,
  .terms-conditions-headers,
  .zonezapp-text,
  .faq-intro h4,
  .faq-intro p {
    color: var(--secondary-font-color);
  }

  .typewriter-cursor {
    color: var(--secondary-color);
  }

  .about-wrapper,
  .marketing-wrapper,
  .stepper-wrapper,
  .footer_section,
  .terms-conditions-container,
  .faq-container,
  .content-section {
    background-color: var(--dm-accent-color);
  }

  .text-highlight {
    color: var(--secondary-font-color);
  }

  .glassmorphism {
    background-color: var(--secondary-color);

    .bi {
      color: var(--app-secondary-bg-color);
    }
  }

  .lead,
  .heading,
  .header h1,
  .text-muted,
  .carousel-header,
  .carousel-sub-header {
    color: var(--secondary-font-color) !important;
  }

  #contact-us,
  .usage-policies-wrapper {
    background-color: var(--dm-primary-color);
  }

  .email_text,
  .chat-body,
  .chat-footer textarea {
    background-color: #3b3b41;
  }

  .mail-link,
  .company-link {
    color: var(--secondary-font-color);

    &:hover {
      color: var(--secondary-color);
    }
  }

  .contact-form input,
  .contact-form textarea,
  .assistant-message,
  .chat-footer {
    background-color: #323236;
  }

  .form-bg {
    background-color: #2b3035;
    border-color: #2b3035 !important;
    .form-control {
      background-color: #212529;
      color: var(--app-secondary-bg-color);
    }
    .text-body-secondary,.form-floating>label{
      color: var(--secondary-font-color) !important;
    }
  }

  .faq-accordion .MuiAccordionSummary-root{
    background-color: var(--secondary-font-color) !important;
  }

  .customerServiceLink{
    color: var(--primary-color);
    &:hover{
      color: var(--secondary-color);
    }
  }
}

body.light {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  color: var(--primary-font-color);
  /* font-family: Verdana, sans-serif !important; */
  background-color: var(--app-primary-bg-color) !important;

  &::-webkit-scrollbar {
    @include webkit-scrollbar();
  }

  &::-webkit-scrollbar-thumb {
    @include webkit-scrollbar-thumb();
  }
}

h1,
h2 {
  font-size: 1em;
  color: var(--primary-font-color);
}

p {
  font-size: 1em;
  line-height: 1.5em;
  color: var(--accent-font-color);
}

.text {
  font-weight: 300 !important;
  font-size: 1.25rem !important;
  text-align: justify !important;
}

.text-highlight {
  font-weight: 600;
  color: var(--primary-font-color);
}

.featurette {
  padding: 3rem 0 !important;
}

.featurette-divider {
  margin: 5rem 0;
}

.featurette-heading {
  line-height: 1;
  font-weight: 300;
  position: relative;
  letter-spacing: -.05rem;
  text-align: left !important;
  color: var(--secondary-color) !important;
}

.features-heading::after {
  content: '';
  display: block;
  width: 6.25%;
  /* Adjust the width to control the length of the line */
  height: 2px;
  /* Adjust the thickness of the line */
  background-color: var(--secondary-color);
  /* Line color */
  margin: 0 auto;
  /* Center the line */
  margin-top: 10px;
  /* Space between the text and the line */
}

.icon-square {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
  border-radius: .75rem;
}

.glassmorphism {
  background: rgba(255, 255, 255, 0.2);
  /* Semi-transparent white background */
  /* border-radius: 16px; */
  /* Rounded corners */
  backdrop-filter: blur(10px);
  /* Blurs the content behind the element */
  -webkit-backdrop-filter: blur(10px);
  /* Safari support for backdrop-filter */
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  /* Subtle shadow */
  border: 1px solid rgba(255, 255, 255, 0.3);
  /* Light border */
  /* padding: 20px; */
  /* Internal spacing */
  color: #ffffff;
  transition: transform 0.3s ease, color 0.3s ease;

  /* White text color */
  /* width: 300px;
  height: 200px; */
  &:hover {
    transform: scale(1.2);
    background: rgb(255, 194, 48, 1);
    background: linear-gradient(145deg, rgb(255, 194, 48, 1) 0%, rgb(255, 124, 0) 100%);
    .bi{
      font-size: 28px;
    }
  }

  .bi {
    color: var(--secondary-color);
    vertical-align: -.125em;
    fill: currentColor;
  }
}

.back-to-top {
  &:hover {
    .expandless-icon {
      color: #ffffff;
    }
  }
}

/*
--------------------------------------------------  
RESPONSIVE CSS
-------------------------------------------------- 
*/

@media (min-width: 40em) {
  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}

@media (max-width: 768px) {

  h1,
  h2,
  .display-5 {
    font-size: 1.8rem;
  }

  .lead {
    font-size: 1rem;
  }

  .featurette-heading {
    font-weight: 600;
  }

}

@media (max-width: 576px) {

  h1,
  h2,
  .display-5 {
    font-size: 1.5rem;
  }

  .lead {
    font-size: 0.875rem;
  }

}