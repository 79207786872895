.layout_padding {
    padding-bottom: 80px;
    padding-top: 80px;
}

.footer_section{
    width: 100%;
    float: left;
    background-color: var(--dm-primary-color);
    height: auto;

}

.dolor_text {
    width: 100%;
    float: left;
    font-size: 12pt;
    color: #fffefa;
    line-height: 53px;
    margin-left: 0;
    font-weight: 100;
}

h1{
    color: #ffffff !important;
}

.top-footer-link{
    color: #ffffff !important;
    text-decoration: none !important;
}

.top-footer-link:hover{
    color: var(--primary-color) !important;
}

.quick_text{
    width: 68%;
    float: left;
    font-size: 24pt;
    color: #ffffff;
    border-bottom: 4px solid var(--secondary-color);
    margin-bottom: 26px;
}

.chevron_arrow{
    width: 100%;
    float: left;
    font-size: 12pt;
    color: #ffffff;
    line-height: 41px;
}

.chevron_arrow_icon{
    width: 8px;
    height: 12px;
    color: var(--secondary-color);
}

.padding-left{
    padding-left: 10px;
}

.subscribe_text{
    width: 90%;
    float: left;
    font-size: 24pt;
    color: #ffffff;
    border-bottom: 4px solid var(--secondary-color);
    margin-bottom: 26px;
}

.ipsum_text{
    width: 100%;
    float: left;
    font-size: 12pt;
    color: #fffefa;
    line-height: 30px;
    margin: 0;
    font-weight: 100;
}

.st-subscribe-message {
    text-align: center;
    display: block;
    padding: 10px 20px;
    color: #fff;
    font-size: 14px;
    clear: both;
}

.email_text{
    width: 100%;
    float: left;
    background-color: #ffffff;
    color: #a09f9b;
    border-radius: 5px;
    padding: 5px 15px;
    margin-top: 20px;
    border: 0px;
    font-size: 14pt;
}

.submit_text{
    width: 60%;
    float: left;
    background-color: var(--secondary-color);
    color: #fbf5f5;
    border-radius: 5px;
    padding: 8px 15px;
    margin-top: 20px;
    border: 0px;
    font-size: 14pt;
}

.submit_text:hover{
    background-color: var(--primary-color);
}

@media (max-width: 992px) {
    .quick-links{
        margin-bottom: 2rem;
    }
}