/* FAQ container */
.faq-container {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 20px;
    background-color: #f4f7fa;
    /* Light background for contrast */
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

/* FAQ Introduction section */
.faq-intro h4 {
    font-size: 2rem;
    margin-top: 50px;
    text-align: center;
    margin-bottom: 20px;
    color: var(--primary-font-color);
}

.faq-intro p {
    color: var(--primary-font-color);
    text-align: center;
    font-size: 1.1rem;
    margin-bottom: 30px;
    font-weight: 300;
}

.o-hue {
    font-weight: bold;
    color: var(--secondary-color);
}

/* FAQ Section titles */
.faq-section h5 {
    font-size: 1.5rem;
    color: var(--secondary-color);
    /* Blue color for headers */
    margin-bottom: 20px;
}

/* Accordion styles */
.faq-accordion {
    margin-bottom: 10px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.expandMoreIcon {
    color: var(--secondary-color);
}

.faq-accordion .MuiAccordionSummary-root {
    background-color: #ffffff;
    /* Blue for accordion headers */
    color: #fff;
    /* border-radius: 16px; */
    padding: 10px 15px;
    transition: background-color 0.3s ease;
}

.faq-accordion .MuiAccordionSummary-root:hover {
    background-color: #ffffff;
    /*#005f8a*/
    /* Darker blue on hover */
}

.faq-accordion .MuiAccordionDetails-root {
    background-color: var(--app-accent-bg-color-one);
    /*#fafafa*/
    /* Light grey background for accordion details */
    padding: 15px;
    font-size: 1rem;
    color: var(--primary-font-color);
    border-top: 1px solid #ddd;
}

/* Typography adjustments */
.faq-accordion .MuiTypography-root {
    font-weight: 200;
    color: var(--primary-font-color);
}

/* Responsive design */
@media (max-width: 768px) {
    .faq-intro h4 {
        font-size: 1.8rem;
    }

    .faq-intro p {
        font-size: 1rem;
    }

    .faq-section h5 {
        font-size: 1.3rem;
    }

    .faq-accordion .MuiAccordionSummary-root {
        font-size: 0.9rem;
        padding: 10px;
    }

    .faq-accordion .MuiAccordionDetails-root {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .faq-intro h4 {
        font-size: 1.5rem;
    }

    .faq-intro p {
        font-size: 0.9rem;
    }

    .faq-section h5 {
        font-size: 1.2rem;
    }

    .faq-accordion .MuiAccordionSummary-root {
        font-size: 0.85rem;
    }

    .faq-accordion .MuiAccordionDetails-root {
        font-size: 0.85rem;
    }
}

@media (min-width: 900px) {
    .css-u4uvlt-MuiContainer-root {
        max-width: 1362px !important;
    }
}