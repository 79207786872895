body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    line-height: 1.6;
    background-color: #f9f9f9;
    color: #333;
}

.policy-container {
    width: 65%; /* max-width: 800px; */
    margin: 8rem auto; /*margin: 2rem auto;*/
    padding: 1rem;
    background: #fff;
    /* border-radius: 8px; */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.policy-header {
    text-align: center;
    margin-bottom: 1rem;

    h1 {
        font-size: 2rem;
        color: #ff8c00;
    }

    h2 {
        font-size: 1.5rem;
        color: var(--primary-font-color);
    }
}

.policy-content,
.policy-usage {
    margin-bottom: 1.5rem;

    h3 {
        font-size: 1.25rem;
        color: #ff8c00;
        margin-top: 1rem;
    }

    ul {
        margin-left: 1.5rem;
        list-style-type: disc;

        li {
            margin-bottom: 0.5rem;
        }
    }

    p {
        margin: 0.5rem 0;
    }

    a {
        color: #ff8c00;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

@media (max-width: 768px) {
    .policy-container {
        padding: 1rem;
    }

    .policy-header h1 {
        font-size: 1.75rem;
    }

    .policy-header h2 {
        font-size: 1.25rem;
    }

    h3 {
        font-size: 1rem;
    }
}