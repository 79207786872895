.marketing-wrapper {
    background-color: var(--app-secondary-bg-color);
}

.start-content {
    margin-left: 0.3rem;
    text-align: start !important;
}

.start-content h4 {
    font-size: 16px !important;
    font-weight: 700 !important;
    color: var(--primary-font-color) !important;
}

.marketing-image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Adjust the height as per your requirement */
}

.marketing-image-one {
    /* width: 335px; */
    width: 322.97px;
    /* height: 698px; */
    /* Set the height you want */
    position: absolute;
    bottom: 3.5rem;
    z-index: 2;
    /* Ensures this image is in front */
    border-radius: 16px;
    /* margin-top: 3.5rem; */
    border: 1.5px solid var(--secondary-color);
}

.marketing-image-two {
    width: 456px; /**556px*/
    height: 598px; /**698px/
    /* Set the height you want */
    position: absolute;
    z-index: 1;
    /* left: 2rem; */
    /* This image will be behind the first image */
}

@media (max-width: 768px) {
    .marketing-image-one {
        bottom: 2rem;
    }

    .marketing-image-two {
        display: none;
    }
}

/* @media (max-width: 576px) {
    .marketing-wrapper{
        background-color: lightblue !important;
    }
} */