.download-wrapper {
    position: relative;
    padding-top: 1rem;
    /* padding: 3rem 0 4rem 0; */
}

.download-image-container {
    display: flex;
    justify-content: center;
    position: relative;

    z-index: 1;
    background-size: contain;
    background-position-x: 95%;
    background-position-y: 55%;
    background-repeat: no-repeat;
    background-image: url(../../../assets/backgrounds/marketing-bg-2.png);
}

.o-hue{
    color: var(--secondary-color);
}

.download-video-cover {
    /* width: 335px; */
    height: 698px;
    /* Set the height you want */
    /* position: absolute;
    bottom: 3.5rem;
    z-index: 2; */
    /* Ensures this image is in front */
    cursor: pointer;
    border-radius: 16px;
    /* margin-top: 3.5rem; */
    border: 1.5px solid var(--secondary-color);

    position: relative;
    top: 4rem;
}

.btn-link-container {
    display: grid;
}

.Typewriter{
    color: var(--primary-font-color);
}


@media (max-width: 768px) {
    .download-wrapper {
        padding: 1rem 0;
    }

    /* .download-image-one {
        height: auto;
    }

    .download-image-two {
        display: none;
    } */

    .display-5 {
        font-size: 1.8rem;
    }

    .lead {
        font-size: 1rem;
    }

    .btn-lg {
        font-size: 0.875rem;
        /*border-radius: 6.25rem;*/
        padding: 0.75rem 1.5rem;
    }

    .expandMore-icon {
        font-size: 1.5rem;
    }

    .btn-link-container {
        display: flex !important;
    }

    .expand-icon-btn {
        width: 60px;
        height: 60px;
        margin-right: 0.1rem;
    }
}

@media (max-width: 576px) {
    .download-wrapper {
        /* padding: 0.5rem 0; */
        padding: 8rem 0 0 0;
    }

    .display-5 {
        font-size: 1.5rem;
    }

    .lead {
        font-size: 0.875rem;
    }

    .btn-lg {
        padding: 0.5rem 1rem;
    }

    .expandMore-icon {
        font-size: 1.2rem;
    }
}