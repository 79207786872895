.css-1cvd0nv-MuiDialog-container {
    height: auto !important;
}

.model-cover {
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url(../../../assets/backgrounds/marketing-bg-2.png);
}

.modal-content {
    background-size: cover;
    background-repeat: no-repeat;
    background-blend-mode: darken;
    background-image: url(../../../assets/svgs/register-02.svg);
}

.terms-conditions-container {
    background-color: #ffffff;
}

.terms-conditions-container h4,
p,
ul {
    text-align: left;
}

.content-wrapper::-webkit-scrollbar {
    width: .50rem;
    border-radius: .5rem;
    background-color: var(--app-acent-bg-color);
}

.content-wrapper::-webkit-scrollbar-thumb {
    border-radius: .5rem;
    background-color: #001e3c;
}

.terms-conditions-link {
    color: var(--secondary-color);
}

.back-link {
    text-align: center;
    border: thin solid #ffffff;
    padding: 16px 28px;
    text-decoration: none;
    color: #ffffff;
    border-radius: 50px;
    background-color: var(--secondary-color);
}




.form-bg {
    background-color: var(--app-primary-bg-color);
}

.popup {
    padding: 15px;
    margin-top: 0.5rem;
    border-radius: 5px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.popup-text {
    /* color: #ffffff; */
    font-weight: 500;
    text-align: center;
    margin-top: 1rem;
}

@media (max-width: 768px) {
    .popup {
        width: 90%;
        font-size: 16px;
    }
    .modal-content{
        width: 100% !important;
    }
}