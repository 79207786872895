/* Chat message bubbles */
.user-message,
.assistant-message {
    max-width: 80%;
    margin: 8px 0;
    padding: 10px;
    border-radius: 18px;
    position: relative;
    word-wrap: break-word;
    line-height: 1.4;
    /* text-transform: capitalize; */
}

.user-message {
    color: #ffffff;
    background-color: #ffa500;
    align-self: flex-end;
    margin-left: auto;
    border-top-right-radius: 0;
    position: relative;
}

.user-message .timestamp {
    color: #ffffff;
}

.assistant-message {
    background-color: #f1f0f0;
    align-self: flex-start;
    margin-right: auto;
    border-top-left-radius: 0;
    position: relative;
}

.timestamp {
    font-size: 12px;
    color: #888;
    position: absolute;
    bottom: 5px;
    right: 10px;
}

/* Floating button */

.close-icon,
.textsms-icon {
    font-size: 30px;
}

.chat-toggle-button {
    background-color: #ffffff;
    color: var(--primary-color);
    border: none;
    padding: 15px;
    border-radius: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    font-size: 24px;
    /*16px*/
    margin: 0.25rem 0;
    width: 44px;
    height: 44px;

    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, box-shadow 0.3s, opacity 0.3s;
}

.chat-toggle-button:hover {
    color: #ffffff;
    background: rgb(255, 194, 48, 1);
    background: linear-gradient(145deg, rgb(255, 194, 48, 1) 0%, rgb(255, 124, 0) 100%);
    /* Adjust hover color as needed */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Chat window */
.chat-window {
    width: 400px;
    height: 400px;
    background-color: white;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

/* Chat header */
.chat-header {
    background: rgb(255, 194, 48, 1);
    background: linear-gradient(145deg, rgb(255, 194, 48, 1) 0%, rgb(255, 124, 0) 100%);

    color: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
}

.close-button {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
}

/* Chat body */
.chat-body {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
    font-size: 14px;
    overflow-x: hidden;
    background-color: var(--app-secondary-bg-color);
}

.chat-body::-webkit-scrollbar {
    width: .50rem;
    border-radius: .5rem;
    background-color: var(--app-acent-bg-color);
}

.chat-body::-webkit-scrollbar-thumb {
    border-radius: .5rem;
    background-color: #ffa500;
}

/* Chat footer */
.chat-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f1f1f1;
}

.chat-footer textarea {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 10px;
    margin-right: 5px;
    height: auto;
}

.chat-input-wrapper {
    display: flex;
    align-items: flex-start;
    /* Align the textarea and button at the top */
    width: 100%;
}

.chat-input {
    flex: 1;
    resize: none;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    min-height: 40px;
    /* Set a minimum height */
    max-height: 150px;
    /* Optionally limit the maximum height */
    overflow-y: auto;
    /* Add a scrollbar when the content exceeds max height */
}

.send-button {
    background-color: orange;
    color: white;
    border: none;
    padding: 10px;
    margin-left: 10px;
    cursor: pointer;
    height: 40px;
    /* Fixed height to ensure the button doesn't resize */
    width: 40px;
    /* You can adjust this width as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.send-button:hover {
    background-color: darkorange;
}

/* Loader styles */
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.dot {
    width: 10px;
    height: 10px;
    background-color: #128c7e;
    border-radius: 50%;
    margin: 0 5px;
    animation: bounce 0.6s infinite alternate;
}

.dot1 {
    animation-delay: 0s;
}

.dot2 {
    animation-delay: 0.1s;
}

.dot3 {
    animation-delay: 0.2s;
}

.dot4 {
    animation-delay: 0.3s;
}

.dot5 {
    animation-delay: 0.4s;
}

@keyframes bounce {
    to {
        transform: translateY(-10px);
    }
}

/* Error message styles */
.error-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    color: red;
}

.error-icon {
    font-size: 40px;
    margin-bottom: 10px;
}

/* Responsive design */
@media (max-width: 600px) {
    .chat-window {
        width: min-content; /* 100% */
        height: 400px; /*8height: 70%;*/
        bottom: 10px;
        right: 5%;
    }

    .chat-toggle-button {
        padding: 10px;
        font-size: 14px;
    }

    .user-message{
        max-width: 90% !important;
    }
}