.stepper-wrapper {
    background-color: var(--app-secondary-bg-color);
    /* padding: 20px; */
    padding-top: 20px;
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensures content and progress bar are spaced apart */
}

.carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-direction: column;
    padding: 30px; /*10px*/
}

.color {
    font-weight: 300;
    color: var(--secondary-color);
}

.heading{
    color: var(--primary-font-color) !important;
}

.heading::after {
    content: '';
    display: block;
    width: 24.25%;
    height: 2px;
    background-color: var(--secondary-color);
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 20px;
}
  

.mobile-frame {
    position: relative;
    width: 90%;
    max-width: 350px;
    height: 100%;
    max-height: 700px;
    padding: 20px;
    border: 16px solid black;
    border-radius: 30px;
    background-color: white;
}

.step-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.step-image {
    width: 100%;
    height: auto;
    max-height: 500px;
    object-fit: cover;
    border-radius: 20px;
}

.step-info {
    text-align: center;
    margin-top: 10px;
}

.slick-dots {
    /* bottom: -30px; */
    margin-bottom: 0.6rem;
    color: var(--secondary-color);
}

.slick-dots li.slick-active button:before {
    color: var(--secondary-color) !important;
}

/* Progress Bar */
.progress-bar-wrapper {
    width: 100%;
    height: 5px;
    background-color: var(--app-acent-bg-color);
    position: relative;
}

.progress-bar {
    height: 100%;
    background-color: var(--secondary-color) !important; /* Orange progress bar */
    transition: width 0.3s ease; /* Smooth transition effect */
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
    .carousel-container {
        padding: 20px;
    }

    .mobile-frame {
        max-width: 300px;
        max-height: 600px;
        padding: 15px;
    }

    .step-image {
        max-height: 400px;
    }
}

@media (max-width: 480px) {
    .mobile-frame {
        max-width: 250px;
        max-height: 500px;
        padding: 10px;
    }

    .step-image {
        max-height: 350px;
    }
}
