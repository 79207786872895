.about-wrapper {
    background-color: var(--app-secondary-bg-color);
}

.about-image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Adjust the height as per your requirement */
}

.about-image-one {
    height: 741px;
    /* Set the height you want */
    position: absolute;
    z-index: 2;
    /* Ensures this image is in front */
}

.about-image-two {
    height: 500px;
    /* Set the height you want */
    position: absolute;
    z-index: 1;
    right: 0;
    /* This image will be behind the first image */
}

@media (max-width: 768px) {
    .about-image-two {
        left: -100px;
    }
}