.terms-conditions-container {
    background-color: var(--app-secondary-bg-color);
}
.terms-conditions-headers {
    color: var(--primary-font-color);
}
@media (max-width: 768px) {
    .terms-conditions-container{
        margin-top: 8rem;
    }
}