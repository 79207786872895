/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
    /* height: 100vh; */
    /* overflow: hidden; */
    margin-top: 4rem;
}

/* Since positioning the image, we need to help out the caption */
.carousel-caption {
    /*padding-bottom: 2.25rem !important;*/ /* 5.25rem */
    /* bottom: 3rem; */
    z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item {
    height: 40rem;
}

.carousel-item>img {
    /* position: absolute;
    top: 0;
    left: 0;
    min-width: 100%; */
    /*height: 32rem;*/

    width: 35%;
    margin: auto 50%;
    height: 40rem;
}

.carousel-header {
    font-weight: 400;
    color: var(--primary-font-color) !important;
}

.carousel-sub-header {
    font-weight: 200;
    font-size: 24px;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
    background-color: var(--secondary-color) !important;
}

/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
    margin-bottom: 1.5rem;
    text-align: center;
}

.marketing h2 {
    font-weight: 400;
}

/* rtl:begin:ignore */
.marketing .col-lg-4 p {
    margin-right: .75rem;
    margin-left: .75rem;
}

/* rtl:end:ignore */


/* Featurettes
------------------------- */

.featurette-divider {
    margin: 5rem 0;
    /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
.featurette-heading {
    font-weight: 300;
    line-height: 1;
    /* rtl:remove */
    letter-spacing: -.05rem;
}

.featurette-image{
    color: var(--app-primary-bg-color-three); /* --app-primary-bg-color-three* --accent-color* */
}

/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {

    /* Bump up size of carousel content */
    .carousel-caption p {
        margin-bottom: 1.25rem;
        font-size: 1.25rem;
        line-height: 1.4;
    }

    .featurette-heading {
        font-size: 50px;
    }
}

@media (min-width: 62em) {
    .featurette-heading {
        margin-top: 7rem;
    }
}

/* Icon
------------------------- */
.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    margin: 0 auto 15px;
    background: #ffffff;
    border-radius: 50%;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.3);
    transition: transform 0.3s ease, color 0.3s ease;
    &:hover {
        transform: scale(1.2);
    }
}

.icon {
    color: var(--secondary-color);
    &:hover {
        vertical-align: -.125em;
    }
}