p {
    margin-bottom: 15px;
}

ol {
    padding-left: 20px;
}

ol li {
    margin-bottom: 10px;
}

strong {
    color: #333;
    font-weight: 500 !important;
}

.text-justify {
    text-align: justify;
}

.about-image-one{
    height: auto !important;
}

@media (max-width: 768px) {
    ol li {
        font-size: 0.9rem;
    }

    .appflow-wrapper{
        margin-top: 14rem;
    }
}