.cover-container {
    position: relative;
    height: 100vh;
    background: url(../../assets/gifs/about.gif) center center/cover no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black overlay */
    z-index: 1;
    /* Ensures the overlay is above the GIF */
}

.content {
    position: relative;
    text-align: center;
    color: white;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
    /* Adds a shadow to make text readable */
    z-index: 2;
    /* Ensures the text is above the overlay */
}

.mission-heading{
    color: #ffffff !important;
}

.mission-text {
    font-weight: 300;
    text-align: center;
    color: #ffffff !important;
    font-size: 1.3rem !important;
    margin-top: 0.5rem !important;
}