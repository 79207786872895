/* Initial visibility of the navbar */
.navbar-show {
    top: 0;
    transition: top 0.3s ease-in-out;
}

/* Hidden state for the navbar */
.navbar-hide {
    top: -100px;
    /* Adjust this based on your navbar's height */
    transition: top 0.3s ease-in-out;
}

.navbar {
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.3);
}

.brand {
    font-size: 24px;
    font-weight: 600;
    margin-left: 0.8rem;
    color: var(--primary-font-color);
}

.nav-item {
    margin: 0 0.5rem;
}

.nav-link {
    font-weight: 400 !important;
    color: var(--primary-font-color);
}

.active,
.nav-link:hover {
    font-weight: 500 !important;
    transform: scale(1.1) !important;
    color: var(--secondary-color) !important;
    transition: transform 0.3s ease !important;
}

.toggle-label {
    color: #000000;
    font-weight: 300;
}

.toggle-label:active {
    font-weight: 600;
}

.MuiButtonBase-root.download-btn,
.MuiButtonBase-root.download-btn:active {
    height: 53px;
    width: 156.89px;
    font-size: 16px;
    color: #ffffff;
    font-weight: 300;
    /* padding: 16px 28px; */
    padding: 16px 0;
    border-radius: 50px;
    margin: 0 16px 0 16px;
    text-transform: capitalize;
    border: thin solid #001e3c;
    animation: colorChange 3s infinite;
    background-color: var(--secondary-color);
}

.navbar-toggler:active {
    color: var(--secondary-color) !important;
    border: var(--bs-border-width) solid var(--secondary-color) !important;
}

.toggler-icon {
    font-size: 2rem !important;
    color: inherit;
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
}

/* General button styles */
.download-btn {
    font-size: 16px;
    padding: 8px 16px;
}

@keyframes colorChange {
    0% {
        background-color: var(--secondary-color);
        /* First color */
    }

    33% {
        background-color: var(--primary-color);
        /* Second color */
    }

    66% {
        background-color: var(--accent-color);
        /* Third color */
    }

    100% {
        background-color: var(--secondary-color);
        /* Looping back to first color */
    }
}



/* Styles for the language IconButton */
.icon-button-label{
    margin-right: 0.5rem;
}

.language-icon{
    color: #ff9800;
    font-size: 30px !important;
}

.icon-btn {
    width: 40px;
    height: 40px;
    margin-left: 8px;
    transition: transform 0.3s ease, color 0.3s ease;
}

/* Change color on hover */
.icon-btn:hover {
    background-color: #ffffff !important;
    transform: scale(1.1); /* Slightly enlarge the icon on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.15);
}



/* Responsive adjustments */
@media (max-width: 992px) {
    .icon-btn {
        color: #ff9800; /* Mobile color */
    }
}

@media (max-width: 576px) {
    .icon-btn {
        color: #ffffff; /* Color resets for small screens */
        transform: scale(1); /* No scaling for small screens to maintain layout */
        margin-left: 4px; /* Smaller margin for compact screens */
    }
}

@media (max-width: 768px) {
    .active {
        margin-left: 0.3rem;
        transform: scale(1) !important;
    }
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .download-btn {
        font-size: 14px;
        /* Smaller font size */
        padding: 6px 12px;
        /* Adjust padding */
        margin-right: 8px;
        /* Reduce margin */
    }

    /* Ensure the button aligns properly in smaller screens */
    .d-flex.ms-auto {
        justify-content: center;
        /* Center align button */
        width: 100%;
        /* Full width on small screens */
    }

    /* Optional: Adjust logo size for mobile */
    /* .navbar-brand img {
        height: 50px;
        width: 55px;
    } */

    /* Adjust spacing in the navbar */
    .navbar-collapse {
        justify-content: space-between;
        /* Ensure nav links and button are spaced well */
    }
}