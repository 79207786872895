footer {
    padding: 40px 0px;
    background: rgb(255, 194, 48, 1);
    background: linear-gradient(145deg, rgb(255, 194, 48, 1) 0%, rgb(255, 124, 0) 100%);
}

footer .left-text-content p {
    color: #fff;
    font-size: 16px;
}

footer .left-text-content a {
    color: #fff;
}

footer .right-text-content {
    float: right;
}

footer .right-text-content p {
    color: #fff;
    font-size: 14px;
    margin-right: 15px;
    text-transform: uppercase;
}

footer .right-text-content ul li {
    display: inline-block;
}

footer .right-text-content ul li a {
    margin: 0 0.15rem;
    width: 32px;
    height: 32px;
    display: inline-block;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    background-color: #fff;
    border-radius: 50%;
    color: var(--secondary-color);
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

footer .right-text-content ul li a:hover {
    background-color: var(--secondary-color);
    color: #fff;
}

.pt-r{
    position: relative;
    top: 1.5rem
}

.right-row{
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 992px) {
    footer .left-text-content p {
        text-align: center;
        margin-bottom: 30px;
    }

    footer .right-text-content {
        float: none;
        text-align: center;
    }

    .right-row{
        /* display: flex;
        align-items: center; */
        justify-content: flex-start;
    }

    .social-icons{
        padding: 0;
    }
}